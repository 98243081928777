@import '/src/styles/mixins.scss';
.footer {
    background-color: var(--color-dark-blue);
    padding: var(--spacing-12) 0;
    color: #FFF;
    &__inner {
        display: flex;
        gap: 6rem;
        justify-content: space-between;
        width: var(--container-vw-width); 
        max-width: var(--container-width); 
        margin: 0 auto;
        padding: 0 2rem;
        @include gridDown(xl) {
            flex-wrap: wrap;
            > div:first-child {
                width: 100%; 
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__menu {
        list-style: none;
        font-size: var(--font-size-button);
        li:not(:last-child) {
            margin-bottom: 1em;
        }
        svg {
            margin-left: 1em;
            font-size: .95em;
        }
    }
}