@import '/src/styles/mixins.scss';
.button {
    display: inline-block; 
    position: relative;
    font-size: var(--font-size-button);
    background-color: var(--color-blue);
    color: #FFF;
    line-height: 1;
    font-weight: 700;
    padding: 1.15em 1.75em;
    border: 2px solid;  
    border-radius: 37px;
    border-color: var(--color-blue);
    box-sizing: content-box;
    cursor: pointer ;
    transition-duration: .2s;

    --spinner-color: #FFF;
    @include gridUp(sm) {
        &:hover, &:focus-visible, &--active {
            background-color: #0A4FA7; 
            border-color: #0A4FA7;
        }
    }
    
    &__spinner {
        --spinner-size: 1.5em;

        position: absolute;
        top: calc( 50% - var(--spinner-size)/2 );
        left: calc( 50% - var(--spinner-size)/2 );
        height: var(--spinner-size) !important;
        width: var(--spinner-size) !important;
        color: var(--spinner-color);
    }

    &--alt {
        background-color: transparent;
        color: var(--color-blue);
        border: 2px solid var(--color-blue);
        --spinner-color: var(--color-blue);
        @include gridUp(sm) {
            &:hover, &:focus-visible, &--active {
                background-color: var(--color-blue);
                border-color: var(--color-blue);
                color: #FFF;
            }
        }
    }

    &--simple {
        background-color: transparent;
        border: 0;
        color: var(--color-blue);
        padding: 0;
        svg {
            margin-left: 0.5em;
            font-size: 1.1em;
        }
        @include gridUp(sm) {
            &:hover, &:focus-visible, &--active {
                background-color: transparent; 
            }
        }
    }

    &--white {
        background-color: #FFF;
        border-color: #FFF;
        color: var(--color-blue);
        @include gridUp(sm) {
            &:hover, &:focus-visible, &--active {
                background-color: transparent; 
                color: #FFF;
                border-color: #FFF;
            }
        }
    }

    &--loading {
        color: transparent;
        pointer-events: none;
    }

    &[disabled] {
        pointer-events: none;
    }
}