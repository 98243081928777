@import '/src/styles/mixins.scss';
@import '/src/styles/functions.scss';
.mobileNavigationWrapper {
    display: none;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    @include gridDown(sm) {
        display: block;
    }
}
.mobileNavigation {
    background-color: #F8F8F6;
    height: var(--frontpage-mobile-navigation-height);
    padding: 1rem #{clamp_helper(10, 140)} 0;
    border-radius: 0 0 3rem 3rem;
}