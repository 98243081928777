@import '/src/styles/functions.scss';
@import '/src/styles/mixins.scss';
/* @import '/src/styles/fonts.scss'; */

:root {
  --color-grey-light-2: #E0E0E0;
  --color-grey-light: #F0F0F0;
  --color-grey-medium: #4E4E4E;
  --color-grey-medium-2: #676767;
  --color-dark-blue: #02245D; 
  --color-blue: #157BFB;
  --color-red: #FC5E76;

  --font-size-h1: #{clamp_helper(28, 54)};
  --font-size-h2: #{clamp_helper(32, 50)};
  --font-size-h3: #{clamp_helper(30, 40)};
  --font-size-h4: #{clamp_helper(25, 35)};
  --font-size-h5: #{clamp_helper(22, 30)};
  --font-size-h6: #{clamp_helper(20, 23)};
  --font-size-body: #{clamp_helper(16, 18)}; 
  --font-size-body-big: #{clamp_helper(18, 25)};
  --font-size-button: #{clamp_helper(18, 20)};
  --font-size-form: 1.6rem;

  --line-height-body: 1.56;

  --container-width: 1620px;
  --container-vw-width: 92vw;
  --page-header-height: 20rem;
  --page-mobile-header-height: 6.6rem;
  --frontpage-mobile-navigation-height: 8.3rem;
  --page-padding-y: #{clamp_helper(40, 80)};
  --page-margin-y: 8rem;
  --content-min-height: calc(100vh - (var(--page-padding-y) * 3) - var(--page-header-height) - var(--page-margin-y));

  --page-view-max-width: 665px;
  --page-view-max-width-wide: 950px;


}

@for $i from 1 through 6 {
  h#{$i}, .h#{$i} {
    font-size: var(--font-size-h#{$i});
    line-height: 1.4; 
    margin-bottom: .8em;
  }
}

.fw-400 {
  font-weight: 400;
}

$spacings: (
  '0': 0,
  '1': 1rem,
  '2': 2rem,
  '3': 3rem,
  '4': #{clamp_helper(30, 40)},
  '6': #{clamp_helper(40, 60)},
  '8': #{clamp_helper(50, 80)},
  '12': #{clamp_helper(100, 120)},
);

$directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left'
);

@each $name, $value in $spacings {
  @each $d, $direction in $directions {
    .m#{$d}-#{$name} {
      margin-#{$direction}: $value !important;
    }
  }
  :root {
    --spacing-#{$name}: #{$value};
  }
}








* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 10px; 
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #80808029;
  color: var(--color-dark-blue); 
  font-family:"new-hero";
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
}

p {
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
input, textarea, button {
  font-family:"new-hero";
}

textarea {
  line-height: var(--line-height-body);
}

p {
  a {
    @include linkBase();
  }
}
a {
  color: inherit;
  text-decoration: none;
  transition-duration: .2s;
}

button {
  transition-duration: .2s;
}

.d-flex {
  display: flex;
}
.justify-content {
  &-end {
    justify-content: flex-end;
  }
}

