@import '/src/styles/functions.scss';
@import '/src/styles/mixins.scss';

.page {
    position: relative;
    &::before {
        content: "";
        position: fixed;
        background-image: image-set(var(--background-image), var(--background-image-fallback)); 
        background-size: cover;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -9999;
    }
    @include gridDown(sm) {
        padding-top: calc( var(--page-mobile-header-height) + 4rem );
    }
    
    
    &__homeLink {
        position: absolute;
        top: 4rem;
        left: 5%;
        @include gridDown(sm) {
            display: none;
        }

    }
    &__mobileHomeLink {
        display: flex;
        align-items: center;
        background-color: #F8F8F6;
        padding: 0 0.75em;
        a, svg {
            display: block;
        }
        svg {
            width: 4rem;
            height: 4rem;
        }
        &--default {
            display: none;
        }
    }
    &__header {
        display: flex;
        align-items: flex-end;
        height: var(--page-header-height);
        color: var(--color-blue);
        svg {
            margin-right: 1em; 
        }
        img {
            max-width: 80%;
        }
        @include gridDown(sm) {
            display: none;
        }
    }

    &__mobileHeaderWrapper {
        display: none;
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100vw;
        @include gridDown(sm) {
            display: block;
        }
    }

    &__mobileHeader {
        display: flex;
        height: var(--page-mobile-header-height);
        background-color: var(--color-blue);
        color: #FFF;
        h1 {
            display: flex;
            justify-content: center;
            flex-basis: 100%;
            align-items: center;
            font-size: 1.7rem;
            margin-bottom: 0;
            padding: 0 0.5em;
            svg {
                display: inline-block;
                width: 3rem;
                margin-right: 1em;
                path {
                    fill: #FFF;
                }
            }
        }
    }

    &__main {
        background-color: #F8F8F6;
        padding: 0 #{clamp_helper(0, 140)} var(--page-padding-y); 
        @include gridDown(sm) {
            padding-left: 0;
            padding-right: 0;
            background-color: transparent; 
        }
        margin: 0 auto var(--page-margin-y); 
        border-radius: 0 0 50px 50px;
        max-width: var(--container-width);
        width: var(--container-vw-width);
    }

    &__mainInner {
        position: relative;
        background-color: #FFF;
        padding: var(--page-padding-y) #{clamp_helper(10, 140)};
        border-radius: 50px;
        @include gridDown(sm) {
            border-radius: 25px;
        } 
    }

    &__mainContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        padding: var(--page-padding-y) 6.5vw;
    }
}