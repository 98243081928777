@import '/src/styles/functions.scss';
.signOut {
    background-color: var(--color-dark-blue);
    color: #FFF;
    line-height: 40px;
    text-align: right;
    padding: 0 clamp_helper(10, 140);
    a {
        color: #FFF;
        font-size: 13px;
        svg {
            margin-left: .25em;
        }
    }
}